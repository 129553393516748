// 话题列表
const getTopicListURL = `/gateway/hc-neighbour/topic/getTopicList`;
//登记表单
const getFormURL = `/gateway/hc-serve/miniapi/forms/formsDetails`;
//表单提交
const formsSubmitURL = `/gateway/hc-serve/miniapi/forms/formsSubmit`;

// 获取SceneParam参数
const getSceneParamUrl = `/gateway/hc-mini/qrcode/getSceneParam`;
export { getTopicListURL, getFormURL, formsSubmitURL, getSceneParamUrl };
